import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import AboutTitleArea from '../containers/about/about-title-area'
import AboutBannerArea from '../containers/about/about-banner-area'
import AboutContentArea from '../containers/about/about-content-area'

class AboutPage extends React.Component {
  render() {
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    return (
      <Layout>
        <SEO 
          title="About" 
          description="About Page description"
          keywords={["about", "about me", "my portfolio"]}
        />
        <Header authorData={author.node}/>
        <div className="main-content">
            <AboutTitleArea/>
            <AboutBannerArea/>
            <AboutContentArea/>
        </div>
        <Footer/>
      </Layout>
    )
  }
}

export default AboutPage

export const aboutQuery = graphql`
  query {
    allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
      edges {
        node {
          ...ContentfulPersonField
        }
      }
    }
  }
`
